import React, { useState } from "react";
import styles from "./walkathon.module.css";
import image1 from "../../assets/flyers/gallery/image1.jpeg";
import image2 from "../../assets/flyers/gallery/image2.jpeg";
import image3 from "../../assets/flyers/gallery/image3.jpeg";
import image4 from "../../assets/flyers/gallery/image4.jpeg";
import image5 from "../../assets/flyers/gallery/image5.jpeg";
import image6 from "../../assets/flyers/gallery/image6.jpeg";
import image7 from "../../assets/flyers/gallery/image7.jpeg";
import Slideshow from "./slideshow/Slideshow";

const Walkathonsection = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const imageArray = [image1, image2, image3, image4, image5, image6, image7];

  const srcSet = imageArray.map((image, index) => {
    return {
      url: image,
      caption: `Image ${index + 1}`,
    };
  });

  return (
    <div className={styles.walkathonMainSection}>
      <section className={styles.problemStatement}>
        <header>
          <h1>WALK AGAINST HUNGER</h1>
          <p>Every step counts...</p>
        </header>
        <div className={styles.info}>
          The "Walk Against Hunger" walkathon, organized by Prachetas
          Foundation, is a vital initiative in our mission to combat hunger and
          promote wellness. By bringing together a diverse group of
          participants, this event raises significant funds and awareness for
          our cause. Each step taken in the walkathon symbolizes our collective
          commitment to addressing food insecurity and ensuring access to
          nutritious meals for underprivileged communities. The funds raised
          through this event directly support our food distribution programs,
          which provide daily meals to hundreds of individuals, including
          children and the elderly.
        </div>
      </section>
      <section className={styles.gallery}>
        <img
          alt="happy benefitiaries"
          src={image1}
          id="image1"
          style={{
            top: "10%",
            rotate: "15deg",
          }}
          className={styles.galleryImage}
        />

        <img
          alt="happy benefitiaries"
          src={image3}
          id="image3"
          style={{
            position: "absolute",
            top: "40%",
            rotate: "7deg",
            left: window.innerWidth < 1400 ? "40%" : "28%",
            zIndex: 99
          }}
          className={styles.galleryImage}
        />
        <img
          alt="happy benefitiaries"
          src={image2}
          id="image2"
          style={{
            position: "absolute",
            top: "50%",
            rotate: "-10deg",
            left: "3%",
          }}
          className={styles.galleryImage}
        />
        <img
          alt="happy benefitiaries"
          src={image4}
          style={{
            position: "absolute",
            top: "65%",
            rotate: "-5deg",
            right: "38%",
          }}
          className={styles.galleryImage}
        />
        <img
          alt="happy benefitiaries"
          src={image5}
          style={{
            position: "absolute",
            top: "5%",
            rotate: "-10deg",
            right: "35%",
          }}
          className={styles.galleryImage}
        />
        <img
          alt="happy benefitiaries"
          src={image6}
          style={{
            position: "absolute",
            top: "55%",
            rotate: "5deg",
            right: "7%",
          }}
          className={styles.galleryImage}
        />
        <img
          alt="happy benefitiaries"
          src={image7}
          style={{
            position: "absolute",
            top: "20%",
            rotate: "10deg",
            right: "15%",
          }}
          className={styles.galleryImage}
        />
      </section>
      <div className={styles.slideshowWrapper}>
        <Slideshow
          imageArray={imageArray}
          delay={3000}
          customStyles={{
            height: "400px",
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
};

export default Walkathonsection;
