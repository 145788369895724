import React from "react";
import styles from "./header.module.css";
import walkagainsthungerlogo from "../../assets/logos/NewLogo.png";
import prachetaslogo from "../../assets/logos/PrachetasLogo.png";

const Header = () => {
  return (
    <div className={styles.headerMain}>
      <div className={styles.logoContainer}>
        <img src={walkagainsthungerlogo} alt="logo" className={styles.logo} />
        <img src={prachetaslogo} alt="logo" className={styles.logo} />
      </div>
    </div>
  );
};

export default Header;
