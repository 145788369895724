import React, { useEffect, useState } from "react";
import styles from "./status.module.css";
import success from "../../assets/success.gif";
import HashLoader from "react-spinners/HashLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// const BACKEND_URL = "http://localhost:5000/";
const BACKEND_URL = "https://walkathon-test-backend.vercel.app/";
// const BACKEND_URL = "https://walkathon-test-env.vercel.app/";

const Status = () => {
  // Hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [statusLoading, setStatusLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [data, setData] = useState(null);
  const id = searchParams.get("id");
  useEffect(() => {
    fetchStatus();
  }, []);

  const fetchStatus = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}status?id=${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success) {
        setData(data.data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    } catch (error) {
      setStatus("error");
      console.log(error);
    } finally {
      setStatusLoading(false);
    }
  };

  function truncateText(text, length) {
    return text.length > length ? text.substring(0, length) + "..." : text;
  }
  return (
    <div className={styles.statusMain}>
      {data && (
        <Helmet>
          <script>
            {`fbq('track', 'Purchase', {value: ${data.amount}, currency: 'INR'})`}
          </script>
          <script>
            {`gtag('event', 'conversion', { 'send_to': 'AW-11071047445/OowuCNPz9MAZEJWOi58p', 'value': ${data.amount}, 'currency': 'INR', 'transaction_id': '${data.transaction_id}' });`}
          </script>
          <script type="application/json">
            {JSON.stringify({
              C_r6jsTMV3BxY: {
                on: "visible",
                vars: {
                  event_name: "conversion",
                  value: data.amount,
                  currency: "INR",
                  transaction_id: data.transaction_id,
                  send_to: ["AW-11071047445/OowuCNPz9MAZEJWOi58p"],
                },
              },
            })}
          </script>
        </Helmet>
      )}
      <div className={styles.statusCard}>
        {statusLoading ? (
          <HashLoader color="black" size={100} />
        ) : status === "success" ? (
          <div className={styles.dataSection}>
            <img src={success} alt="success" className={styles.successImg} />
            <h1 className={styles.successHeading}>Success</h1>
            {data && (
              <div>
                <h3>Hello, {data?.name || "Customer"} </h3>
                <p>
                  We are glad to inform you that your registration for Walk
                  Against Hunger with Prachetas Foundation is Complete!
                </p>
                <p>Here is what we received: </p>
                <ul>
                  <li>
                    <p>Name: </p>
                    <p>{data.name}</p>
                  </li>
                  <li>
                    <p>Phone: </p>
                    <p>{data.phone}</p>
                  </li>
                  {data.email && (
                    <li>
                      <p>Email: </p>
                      <p>{data.email}</p>
                    </li>
                  )}
                  {data.gender && (
                    <li>
                      <p>Gender:</p>
                      <p>{data.gender}</p>
                    </li>
                  )}
                  {data.dob && (
                    <li>
                      <p>Date of Birth:</p>
                      <p>{data.dob}</p>
                    </li>
                  )}
                </ul>
                <div className={styles.memberList}>
                  <div className={styles.memberHeader}>
                    <div className={styles.gridItem}>Name</div>
                    <div className={styles.gridItem}>Size</div>
                    <div className={styles.gridItem}>Distance</div>
                    <div className={styles.gridItem}>Price</div>
                  </div>
                  {data?.members?.length
                    ? data.members?.map((member, index) => (
                        <div className={styles.memberRow} key={index}>
                          <div className={styles.gridItem}>
                            {truncateText(member.name, 15)}
                          </div>
                          <div className={styles.gridItem}>
                            {member.tshirtSize}
                          </div>
                          <div className={styles.gridItem}>
                            {member.distance}
                          </div>
                          <div className={styles.gridItem}>{member.amount}</div>
                        </div>
                      ))
                    : null}
                </div>
                <div className={styles.totalAmount}>
                  <p>Total Amount: ₹ {data.amount}</p>
                </div>
                <p
                  style={{
                    marginTop: "15px",
                    color: "gray",
                  }}
                >
                  We'll be sending you more information soon about the event
                  schedule and venue on WhatsApp. In the meantime, if you have
                  any questions, please don't hesitate to contact us. See you at
                  the walkathon!
                </p>
                <p
                  style={{
                    marginTop: "15px",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  Prachetas Foundation
                </p>
                <p
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    marginTop: "15px",
                  }}
                  onClick={() => navigate("/")}
                >
                  Back to home
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.failed}>
            <FontAwesomeIcon icon={faTimesCircle} size="5x" color="red" />
            <h1>Failed</h1>
            <p>Sorry, we couldn't find your registration. Please try again.</p>
            <p>If you are sure you have registered, please contact us at</p>
            <div className={styles.contactInfo}>
              <div className={styles.contactElement}>
                <FontAwesomeIcon icon={faPhone} />
                7020408811
              </div>
              <div className={styles.contactElement}>
                <FontAwesomeIcon icon={faPhone} />
                8888808112
              </div>
              <div className={styles.contactElement}>
                <FontAwesomeIcon icon={faEnvelope} />
                prachetasfoundation@gmail.com
              </div>
            </div>
            <p
              style={{
                color: "blue",
                cursor: "pointer",
                marginTop: "15px",
              }}
              onClick={() => navigate("/")}
            >
              Back to home
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Status;
