import React from "react";
import styles from "./footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import {
  faBlog,
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div className={styles.footerMain}>
      
      <div className={styles.contactInfo}>
        <p>
          <span>
            <FontAwesomeIcon icon={faEnvelope} />
          </span>{" "}
          prachetasfoundation@gmail.com
        </p>
        <p>
          <span>
            <FontAwesomeIcon icon={faPhone} />{" "}
          </span>{" "}
          88888 08112
        </p>
        <p>
          <span>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </span>{" "}
          Pimple Nilakh, Pune
        </p>
      </div>
      <div className={styles.socialMedia}>
        <ul>
          <a href="https://www.instagram.com/prachetasfoundation/">
            <li title="Instagram">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </li>
          </a>
          <a href="#">
            <li title="Youtube">
              <FontAwesomeIcon icon={faYoutube} size="2x" />
            </li>
          </a>
          <a href="https://www.facebook.com/prachetasfoundation">
            <li title="Facebook">
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </li>
          </a>
          <a href="https://www.linkedin.com/company/prachetas-foundation/">
            <li title="Linkedin">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </li>
          </a>
          <a href="https://prachetasfoundation.blogspot.com/">
            <li title="Blogs">
              <FontAwesomeIcon icon={faBlog} size="2x" />
            </li>
          </a>
        </ul>
        <p>Social Media</p>
      </div>
      <div className={styles.copyRight}>
        <p>© 2024 Prachetas Foundation. All Rights Reserved.</p>
        <div className={styles.terms}>
          <a href="privacypolicy.html">Privacy Policy</a>
          <a href="termsandconditions.html">Terms and Conditions</a>
          <a href="refundpolicy.html">Refund Policy</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
