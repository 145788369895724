import React from "react";
import styles from "./Gallery.module.css";
import blogs from "../../assets/blogs";
import BlogCard from "./BlogCard/BlogCard";
import logo from "../../assets/logos/NewLogo.png";

// for blog 1

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const Gallery = () => {
  const blog1Gallery = importAll(
    require.context("../../assets/blog1", false, /\.(png|jpe?g|svg)$/)
  );
  const blog1srcSet = Object.entries(blog1Gallery).map(([key, src]) => src);

  const blog2Gallery = importAll(
    require.context("../../assets/blog2", false, /\.(png|jpe?g|svg)$/)
  );
  const blog2srcSet = Object.entries(blog2Gallery).map(([key, src]) => src);

  const blogsnew = blogs.map((blog, index) => {
    if (blog.id === 1) {
      return { ...blog, srcSet: blog1srcSet };
    } else if (blog.id === 2) {
      return { ...blog, srcSet: blog2srcSet };
    } else if (blog.id === 3) {
      return { ...blog, srcSet: [logo] };
    }
    return blog;
  });



  return (
      <div className={styles.galleryMain}>
        <h1 className={styles.galleryHeading}>Gallery</h1>
        {blogsnew.map((blog, index) => {
          return <BlogCard key={index} srcSet={blog?.srcSet} blog={blog} />;
        })}
      </div>
    );
  
};

export default Gallery;
