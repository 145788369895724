import React from "react";
import banner1 from "../../assets/flyers/iloveimg-compressed/updated_banner2.png";
import banner2 from "../../assets/flyers/iloveimg-compressed/Updated_banner1.png";
import mainBanner from "../../assets/flyers/bannerFinal2.jpeg";
import styles from "./herosection.module.css";
import Header from "../Header/Header";
import { Link } from "react-scroll";
import Slideshow from "../WalkathonSection/slideshow/Slideshow";
import iskonLogo from "../../assets/logos/iskonlogo.png";
import indianOilLogo from "../../assets/logos/indianOil2.jpeg";

const HeroSection = () => {
  return (
    <div className={styles.heroMain}>
      <div className={styles.bannerContainer}>
        <Slideshow
          imageArray={[mainBanner, banner1, banner2]}
          delay={10000}
          fullHeight
          fullWidth
          borderRadius="none"
          objectFit="contain"
          customStyles={
            window.innerWidth < 450
              ? {
                  height: "fit-content",
                  maxHeight: "400px",
                  objectFit: "contain",
                }
              : {}
          }
        />
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.infoSectionHeader}>
          <Header />
          <div className={styles.iskonLogoWrapper}>
            <p style={{
              margin: "5px 0"
            }}>Powered by</p>
            <div>
              <img
                src={iskonLogo}
                alt="iskon logo"
                className={styles.iskonLogo}
              />
              <img
                src={indianOilLogo}
                alt="indian oil logo"
                className={styles.indianOilLogo}
              />
            </div>
          </div>
        </div>
        <h1>WALK AGAINST HUNGER</h1>
        <p>
          {" "}
          Join our Walkathon - a march against hunger and towards a brighter
          tomorrow. Let's walk as one, raise awareness, and end hunger step by
          step. Register now and be a part of the solution!
        </p>
        <Link to="register" spy={true}>
          <button className={styles.button}>Register Now</button>
        </Link>
      </div>
    </div>
  );
};

export default HeroSection;
