const blogs = [
  {
    id: 1,
    title: "Empty Plates to Hopeful Hearts: Food for Life Drive at Dange Chowk",
    content: `At Prachetas Foundation, we believe in the power of community to nourish not just bodies, but spirits. That's why we were thrilled to partner with ISKCON BCEC Vishal Nagar for the    "Food For Life: Annadan drive" at Dange Chowk, Pimpri-Chinchwad, Pune.
    Dange Chowk wasn't a random pick. This bustling hub is where countless daily wage earners wait for a chance to earn their daily bread. Finding work is a gamble - a successful day means a full table, while no job can leave plates empty.
    The announcement of the food drive brought a wave of hope to the chowk. A long line formed, anticipation replacing uncertainty in the eyes waiting their turn. Each person received a hearty bowl of masala rice and a refreshing cup of buttermilk, a gesture that brought a warm smile to their faces.
    For our Prachetas student volunteers, it wasn't just about handing out food. Filling each plate and witnessing the grateful expressions was a overwhelming pleasure. It's a feeling that comes from knowing you've directly impacted someone's day, transformed empty plates into a sense of security and well-being.
    This drive wouldn't have been possible without the unwavering dedication of our partners. A big thanks goes to Prachetas' donor Shri Krishnmachari Anand, our partner ISKCON BCEC representatives Mr. Hridyanath Patil and Mr. Chandrakant Godbole, along with Prachetas Coordinator Mr. Pradeepkumar Sankaran who carried out this drive successfully. Their tireless work ensured a smooth distribution, guaranteeing a nourishing meal for everyone in need.
    The joy of seeing empty plates replaced with satisfied smiles – that's a feeling unlike any other. It's a reminder of the power of community and the transformative impact we can create together. `,
    link: "https://prachetasfoundation.blogspot.com/",
  },
  {
    id: 2,
    title:
      " Food For Thought: Prachetas Foundation's Heartwarming Food Donation Drive ",
    content: `On June 5, 2024, Prachetas Foundation, in collaboration with ISKCON BCEC Vishal Nagar's Food For Life program, successfully carried out another impactful food donation drive. Sponsored by Shri Prashant Choudhar in remembrance of his uncle, the late Dr. Bharat Pandurang Choudhar, this event highlighted the spirit of giving and community support.

    The initiative began early in the day with the student interns from COEP Technological University packing 50 packets of masala rice at the Prachetas office. These young volunteers were full of energy and enthusiasm as they prepared for the distribution.
    
    The drive took place on Workers' Street, Dange Chowk, Pimpri Chinchwad. The response from the community was overwhelming; the packets of masala rice were distributed quickly, leaving many in the crowd visibly relieved and grateful. With the pre-packed packets exhausted, the team seamlessly transitioned to distributing masala rice in bowls, filling them from a large container to ensure everyone received a portion.
    
    A particularly poignant moment occurred when an anonymous man, moved by the gesture, offered a 10-rupee donation to the student interns. This small but significant contribution was the first donation collected by the Foundation and deeply touched the students, making them more aware of the impact of their efforts.
    
    Throughout the day, many recipients expressed their gratitude and satisfaction, sharing heartfelt thanks for the nutritious food they received. Their appreciation served as a powerful reminder of the importance of such initiatives, reinforcing the Foundation’s mission to support those in need.
    
    This successful event has further motivated Prachetas Foundation to continue their efforts in organizing similar drives in the future. The joy of seeing workers relieved from the fear of going to bed hungry and the positive interactions with the community reaffirmed the Foundation’s commitment to making a difference.
    
    The Prachetas Foundation extends its sincere thanks to Shri Prashant Choudhar for his sponsorship, the ISKCON BCEC Vishal Nagar Food For Life program for their partnership, and the dedicated student interns from COEP Technological University for their hard work. Together, we are making strides towards a more caring and compassionate society.`,
    link: 'https://prachetasfoundation.blogspot.com/2024/06/food-for-thought-prachetas-foundations.html'
  },

  {
    id: 3,
    title:
      "Walk for a Cause: Join the “Walk against Hunger” Walkathon by Prachetas Foundation! ",
    content: `Calling all Pimpri Chinchwad residents! Prachetas Foundation, a local NGO, is hosting its first-ever social event - the “Walk against Hunger” Walkathon! This exciting event is your chance to stretch your legs, support a good cause, and fight hunger in our community.

    The walkathon's name, “Walk against Hunger” - a fitting title for this event focused on providing food for the underprivileged.
    
    By registering for the walkathon, you'll be contributing to Prachetas Foundation's efforts to distribute food to those in need. But that's not all! There's more to gain from joining this event than just a healthy walk.
    
    Stay tuned for our next post, where we'll delve deeper into the exciting benefits that await registered participants. In the meantime, spread the word and get ready to walk for a cause at the “Walk against Hunger” Walkathon!
    
    
    `,
    link: 'https://prachetasfoundation.blogspot.com/2024/06/walk-for-cause-join-walk-against-hunger.html'
  },

  
];
export default blogs;
// {
//   id: 1,
//   title:
//     "Walk for a Cause: Join the Annaparikrama Walkathon by Prachetas Foundation!",
//   content: `Calling all Pimpri Chinchwad residents! Prachetas Foundation, a local NGO, is hosting its first-ever social event - the Annaparikrama Walkathon! This exciting event is your chance to stretch your legs, support a good cause, and fight hunger in our community.\n
//     The walkathon's name, Annaparikrama, translates to "food pilgrimage" - a fitting title for this event focused on providing food for the underprivileged. Here's the best part: participation is completely free!\n
//     By registering for the walkathon, you'll be contributing to Prachetas Foundation's efforts to distribute food to those in need. But that's not all! There's more to gain from joining this event than just a healthy walk.\n
//     Stay tuned for our next post, where we'll delve deeper into the exciting benefits that await registered participants. In the meantime, spread the word and get ready to walk for a cause at the Annaparikrama Walkathon!\n`,
// },
// {
//   id: 2,
//   title:
//     "Walk with Us, Fight Hunger: The Rewards of Participating in the Annaparikrama Walkathon",
//   content: `In our previous post, we introduced the Annaparikrama Walkathon - a Prachetas Foundation initiative to combat hunger in Pimpri Chinchwad. But why should you participate? Here's how this walkathon goes beyond a simple stroll and offers a chance to make a real difference:\n
//     • Fight Hunger in Our Community: Every donation made through your registration goes directly towards procuring food for the underprivileged. Your participation translates to putting meals on the tables of those who need them most.\n
//     • Become a Beacon of Hope: Hunger can have devastating consequences. By contributing to Prachetas Foundation's efforts, you'll be offering a helping hand and fostering a sense of hope for those struggling with food insecurity.\n
//     • Invest in a Stronger Society: A community thrives when its members are healthy and nourished. By ensuring access to food, we empower individuals and families, leading to a more stable and resilient society for all.\n
//     Remember, registration for the Annaparikrama Walkathon is completely free! So lace up your walking shoes, donate what you can, and join us for a walk that nourishes not just your body, but also the lives of others. Let's walk together towards a hunger-free Pimpri Chinchwad!\n
// `,
// },
