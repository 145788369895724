import React from "react";
import styles from "./annadan.module.css";

const Annadan = () => {
  return (
    <div className={styles.annadanMain}>
      {/* <h1>Annadan</h1> */}
      <div className={styles.shlokWrapper}>
        <p className={styles.shlok}>अन्नदानं परं दानं विद्यादानमतः परम् ।</p>
        <p className={styles.shlok}>
          अन्नेन क्षणिका तृप्तिर्यावज्जीवं च विद्यया ॥
        </p>
      </div>
      <div className={styles.annadanContent}>
        <p className={styles.translation}>
          "The offering of food is great; the offering of education is even
          greater. While food satisfies only momentarily, education satisfies
          during the entire course of life."
        </p>
        <p className={styles.content}>
          This profound shloka emphasizes the lasting impact of knowledge
          compared to the temporary relief of hunger. While food satiates
          immediate physical needs, education empowers individuals to navigate
          life's challenges, build a brighter future, and contribute
          meaningfully to society. This philosophy aligns beautifully with the
          goals of Prachetas Foundation. While food donation drives undoubtedly
          play a crucial role in alleviating hunger, Prachetas Foundation
          understands the transformative power of education. By equipping
          individuals with knowledge and skills, the foundation empowers them to
          break the cycle of poverty and achieve self-sufficiency. This
          two-pronged approach, addressing both immediate needs and long-term
          potential, reflects Prachetas Foundation's dedication to holistic
          well-being. Food donation drives provide essential sustenance,
          ensuring individuals have the energy and focus to learn. Education, on
          the other hand, equips them with the tools to build a life of dignity
          and contribute positively to their communities. The following images
          and blog posts attached to this webpage will showcase Prachetas
          Foundation's impactful work in action.
        </p>
      </div>
    </div>
  );
};

export default Annadan;
