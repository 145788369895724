import React, { useEffect, useState } from "react";
import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react";
import styles from "./register.module.css";
import Countdown from "../Countdown/Countdown";
import logo from "../../assets/logos/NewLogo.png";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import HashLoader from "react-spinners/HashLoader";
import closedImg from "../../assets/closed.png";
import { Link, useLocation } from "react-router-dom";

// const BACKEND_URL = "http://localhost:5000";

const Register = () => {
  /* Hooks */
  const location = useLocation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    dob: "",
    gender: "",
    members: [],
  });
  const [memberFormData, setMemberFormData] = useState({
    name: "",
    tshirtSize: "",
    distance: "",
    amount: "",
  });
  const [step1ready, setStep1Ready] = useState(false);
  const [memberFormReady, setMemberFormReady] = useState(false);
  const [step, setStep] = useState(1);
  const [warning, setWarning] = useState({ type: "main", message: "" });
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentLoading, setPaymentLoading] = useState(false);

  useEffect(() => {
    if (formData.members.length === 0) {
      setMemberFormData({
        ...memberFormData,
        name: formData.name,
      });
    }
    if (formData.name.trim() && formData.phone.trim()) {
      setWarning({ type: "main", message: "" });
      if (formData.phone.trim().length !== 10) {
        setWarning({
          type: "phone",
          message: "Phone number should be of 10 digits!",
        });
        setStep1Ready(false);
        return;
      }
      setStep1Ready(true);
    } else {
      setWarning({
        type: "main",
        message: "Please fill all the required fields!",
      });
      setStep1Ready(false);
    }
  }, [formData]);

  useEffect(() => {
    if (memberFormData.distance === "3KM") {
      memberFormData.amount = 399;
    } else if (memberFormData.distance === "5KM") {
      memberFormData.amount = 499;
    }
    if (hasEmptyFields()) setMemberFormReady(false);
    else setMemberFormReady(true);
  }, [memberFormData]);

  const handleInputChange = (e) => {
    if (e.target.name === "phone" && isNaN(e.target.value)) return;
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleMemberInputChange = (e) => {
    setMemberFormData({
      ...memberFormData,
      [e.target.name]: e.target.value,
    });
  };

  const hasEmptyValues = (memberFormData) => {
    return Object.entries(memberFormData).some(
      ([key, value]) => value === undefined || value.trim() === ""
    );
  };

  const hasEmptyFields = () => {
    return Object.entries(memberFormData).some(([key, value]) => {
      if (key !== "amount") {
        return value.trim() === "";
      }
      return false;
    });
  };
  function addMember(e) {
    e.preventDefault();
    if (hasEmptyFields()) {
      alert("Please fill all the fields!");
      return;
    }

    const member = {
      ...memberFormData,
      amount: memberFormData.distance === "3KM" ? 399 : 499,
    };

    setFormData({
      ...formData,
      members: [...formData.members, member],
    });
    setTotalAmount(totalAmount + member.amount);
    setMemberFormData({
      name: "",
      tshirtSize: "",
      distance: "",
      amount: "",
    });
  }

  function removeMember(member) {
    const updatedMembers = formData.members.filter(
      (m) => m.name !== member.name
    );
    setFormData({
      ...formData,
      members: updatedMembers,
    });
    setTotalAmount(totalAmount - member.amount);
  }

  function truncateText(text, length) {
    return text.length > length ? text.substring(0, length) + "..." : text;
  }

  return (
    <div className={styles.registerContainer}>
      <div className={styles.registerMain}>
        {/*<section className={styles.formWrapper}>
          {step === 1 && (
            <motion.form
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className={styles.form}
            >
              <h1 className={styles.heading}>
                Register
              </h1>
              {warning.type == "main" && (
                <AnimatePresence>
                  <motion.p
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ color: "red", transitionDuration: "0.5s" }}
                  >
                    {warning.message}
                  </motion.p>
                </AnimatePresence>
              )}
              <FormControl id="name">
                <FormLabel>Name*</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl id="phone">
                <FormLabel>Phone*</FormLabel>
                <Input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                {warning.type === "phone" && (
                  <AnimatePresence>
                    <motion.p
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                      style={{
                        color: "red",
                        margin: "8px 0",
                      }}
                    >
                      {warning.message}
                    </motion.p>
                  </AnimatePresence>
                )}
              </FormControl>
              <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>DOB</FormLabel>
                <Input
                  type="date"
                  name="dob"
                  onChange={handleInputChange}
                  value={formData.dob}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Gender</FormLabel>
                <Select
                  // placeholder="Gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  name="gender"
                >
                  <option value="" disabled>
                    Select Gender
                  </option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHER">Other</option>
                </Select>
              </FormControl>
              <button
                className={styles.button}
                disabled={!step1ready}
                style={
                  !step1ready
                    ? {
                        backgroundColor: "gray",
                        cursor: "not-allowed",
                      }
                    : {}
                }
                onClick={() => setStep(2)}
              >
                Next
              </button>
            </motion.form>
          )}
          {step === 2 && (
            <motion.div
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className={styles.step2}
            >
              <div>
                <form className={styles.memberForm}>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Name"
                      style={{
                        flex: 2,
                        minWidth: "200px",
                      }}
                      name="name"
                      value={memberFormData.name}
                      onChange={handleMemberInputChange}
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      flex: 1,
                      // minWidth: "70px",
                    }}
                  >
                    <Select
                      // placeholder="T-shirt Size"
                      name="tshirtSize"
                      value={memberFormData.tshirtSize}
                      onChange={handleMemberInputChange}
                    >
                      <option value="" disabled>
                        Select T-shirt size
                      </option>
                      <option value="XS">XS</option>
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="XXL">XXL</option>
                    </Select>
                  </FormControl>
                  <FormControl
                    style={{
                      flex: 1,
                    }}
                  >
                    <Select
                      name="distance"
                      value={memberFormData.distance}
                      onChange={handleMemberInputChange}
                    >
                      <option value="" disabled>
                        Select distance
                      </option>
                      <option value="3KM">3KM</option>
                      <option value="5KM">5KM</option>
                    </Select>
                  </FormControl>
                </form>
                <div className={styles.btnWrapper}>
                  <button
                    disabled={!memberFormReady}
                    className={styles.primaryBtn}
                    onClick={addMember}
                    style={
                      memberFormReady
                        ? {}
                        : { backgroundColor: "gray", cursor: "not-allowed" }
                    }
                  >
                    Add Member
                  </button>
                </div>
              </div>
              <div className={styles.memberList}>
                <div className={styles.memberHeader}>
                  <div className={styles.gridItem}>Name</div>
                  <div className={styles.gridItem}>Size</div>
                  <div className={styles.gridItem}>Distance</div>
                  <div className={styles.gridItem}>Price</div>
                  <div className={styles.gridItem}></div>
                </div>
                {formData.members.length ? (
                  formData.members.map((member, index) => (
                    <div className={styles.memberRow} key={index}>
                      <div className={styles.gridItem}>
                        {truncateText(member.name, 15)}
                      </div>
                      <div className={styles.gridItem}>{member.tshirtSize}</div>
                      <div className={styles.gridItem}>{member.distance}</div>
                      <div className={styles.gridItem}>{member.amount}</div>
                      <div
                        className={styles.gridItem}
                        onClick={(e) => {
                          e.preventDefault();
                          removeMember(member);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    </div>
                  ))
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      color: "gray",
                      marginTop: "20px",
                    }}
                  >
                    No members added yet!
                  </p>
                )}
              </div>
              <div className={styles.totalAmount}>
                <p>Total Amount: ₹{totalAmount}</p>
              </div>

              <div className={styles.buttonGroup}>
                <button
                  className={styles.button}
                  onClick={() => setStep(1)}
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #000",
                    color: "black",
                    cursor: "pointer",
                  }}
                >
                  Back
                </button>
                <button
                  className={styles.button}
                  style={
                    !formData.members.length
                      ? {
                          backgroundColor: "gray",
                          cursor: "not-allowed",
                        }
                      : {}
                  }
                  onClick={handleRegisterFormSubmit}
                >
                  Submit
                </button>
              </div>
            </motion.div>
          )}
        </section>*/}
        <section className={styles.regClosed}>
          <img src={closedImg} alt="closed" />
          <h1>
            Sorry!<br></br> Registrations are closed
          </h1>
          <p>
            Please check out our annadan activities and contact our office for
            further information on how you can be a part of Prachetas, thank you
            for showing interest in our event. We will see you in future. ❤️
          </p>
          {location?.pathname === "/registration" && (
            <Link
              to="/"
              style={{
                color: "blue",
                fontSize: "1.2rem",
                marginTop: "10px",
              }}
            >
              Return to Home
            </Link>
          )}
        </section>
        <section className={styles.countdownWrapper}>
          <h2
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "black",
              marginTop: "1.5rem",
              fontFamily: '"Outfit", sans-serif',
              textAlign: "center"
            }}
          >
            Be a part of the movement
          </h2>
          <img src={logo} className={styles.logo} alt="logo" />
          <Countdown />
        </section>
      </div>
      {paymentLoading && (
        <div className={styles.loaderContainer}>
          <HashLoader color="white" size={100} />
        </div>
      )}
    </div>
  );
};

export default Register;
