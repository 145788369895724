import React from "react";
import styles from "./slideshow.module.css";

function Slideshow({
  imageArray,
  delay,
  fullWidth,
  borderRadius,
  fullHeight,
  customStyles = {},
}) {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === imageArray.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div
      className={styles.slideshow}
      style={{
        width: fullWidth ? "100%" : 'fit-content',
        maxWidth: fullWidth && "95%",
        height: fullHeight && "fit-content",
      }}
    >
      <div
        className={styles.slideshowSlider}
        style={{
          transform: `translate3d(${-index * 100}%, 0, 0)`,
          borderRadius: borderRadius === "none" ? "0px" : "inherit",
          height: fullHeight ? "fit-content" : "",
          maxHeight: fullHeight ? "100%" : "",
        }}
      >
        {imageArray.map((image, index) => (
          <img
            src={image}
            className={styles.slide}
            style={{
              objectFit: "contain",
              transitionDuration: "0,3s",
              height: fullHeight ? "fit-content" : "",
              borderRadius: borderRadius === "none" ? "0px" : "",
              ...customStyles,
            }}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}
export default Slideshow;
