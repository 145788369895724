import React from 'react'
import styles from './HomeMain.module.css'
import HeroSection from '../HeroSection/HeroSection'
import Countdown from '../Countdown/Countdown'

const HomeMain = () => {
  return (
    <div className={styles.homeMain}>
        <HeroSection />
        {/* <Countdown /> */}
    </div>
  )
}

export default HomeMain