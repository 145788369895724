import React, { useEffect, useRef, useState } from "react";
import Slideshow from "../../WalkathonSection/slideshow/Slideshow";
import styles from "./blogCard.module.css";
import useInView from "../../../Hooks/useInView";
import { motion } from "framer-motion";

const BlogCard = ({ srcSet, blog }) => {
  function truncate(str, num) {
    return str.length > num ? str.slice(0, num) + "..." : str;
  }

  const blogref = useRef(null);
  const [hasBlogAnimated, setHasBlogAnimated] = useState(false);
  const isBlogInView = useInView(blogref, { threshold: 0.5 });

  useEffect(() => {
    if (isBlogInView && !hasBlogAnimated) {
      setHasBlogAnimated(true);
    }
  }, [isBlogInView, hasBlogAnimated]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={hasBlogAnimated ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5, bounce: 0.5, delay: 0.2 }}
      ref={blogref}
      className={styles.blogCardMain}
    >
      <h3>{blog.title}</h3>
      <div className={styles.blogContentWrapper}>
        {srcSet?.length > 1 ? (
          <div className={styles.slideShowWrapper}>
            <Slideshow
              imageArray={srcSet}
              delay={3000}
              customStyles={{
                height: "400px",
                objectFit: "cover",
              }}
            />
          </div>
        ) : srcSet.length === 1 ? (
          <div className={styles.slideShowWrapper}>
            <div className={styles.imgWrapper}>
              <img src={srcSet[0]} className={styles.blogImage} alt="blog" />
            </div>
          </div>
        ) : null}

        <div className={styles.blogCard}>
          <p>{truncate(blog.content, 250)}</p>
          <a href={blog.link} target="_blank" rel="noreferrer">
            <button className={styles.button}>Read More</button>
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default BlogCard;
