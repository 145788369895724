import React from "react";
import styles from "./stickybutton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPaper, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-scroll";

const StickyButton = () => {
  return (
    <div className={styles.stickyMain}>
      <Link to="register">
        <div className={styles.iconWrapper}>Register Now</div>
      </Link>
    </div>
  );
};

export default StickyButton;
