import React from "react";
import styles from "./walkathondetails.module.css";
import map1 from "../../assets/maps/3kmNew.jpeg";
import map2 from "../../assets/maps/5kmNew.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCalendar,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Tag } from "@chakra-ui/react";
import { Link } from "react-scroll";

const WalkathonDetails = () => {
  return (
    <div className={styles.walkathonDetailsMain}>
      <h1>Event Details</h1>
      <div className={styles.eventCards}>
        <div className={styles.eventCard}>
          <img src={map1} alt="3km map"/>
          <div className={styles.eventCardText}>
            <h2>3 KM Walkathon</h2>
            <p>
              <FontAwesomeIcon
                style={{
                  marginRight: "10px",
                }}
                icon={faClock}
              />{" "}
              6:30 AM
            </p>
            <p>
              <FontAwesomeIcon
                style={{
                  marginRight: "10px",
                }}
                icon={faCalendar}
              />{" "}
              28th July 2024
            </p>
            <p>
              <FontAwesomeIcon
                style={{
                  marginRight: "10px",
                }}
                icon={faMapMarkerAlt}
              />{" "}
              Vishal Nagar, Pimple Nilakh, Pune
            </p>
          </div>
          <div className={styles.inclusive}>
            <Tag
              style={{ margin: "5px", minWidth: "50px", padding: "5px 7px" }}
            >
              T shirt
            </Tag>
            <Tag
              style={{ margin: "5px", minWidth: "50px", padding: "5px 7px" }}
            >
              Medal
            </Tag>
            <Tag
              style={{ margin: "5px", minWidth: "50px", padding: "5px 7px" }}
            >
              Refreshments
            </Tag>
            <Tag
              style={{ margin: "5px", minWidth: "50px", padding: "5px 7px" }}
            >
              E Certificate
            </Tag>
          </div>
          <div className={styles.price}>
            <h1>
              ₹ 399 <span>/Ticket</span>
            </h1>
            <Link to="register" spy={true}>
              <button className={styles.button}>Register Now</button>
            </Link>
          </div>
        </div>
        <div className={styles.eventCard}>
          <img src={map2} alt="5km map"/>
          <div className={styles.eventCardText}>
            <h2>5 KM Walkathon</h2>
            <p>
              <FontAwesomeIcon
                style={{
                  marginRight: "10px",
                }}
                icon={faClock}
              />{" "}
              6:30 AM
            </p>
            <p>
              <FontAwesomeIcon
                style={{
                  marginRight: "10px",
                }}
                icon={faCalendar}
              />{" "}
              28th July 2024
            </p>
            <p>
              <FontAwesomeIcon
                style={{
                  marginRight: "10px",
                }}
                icon={faMapMarkerAlt}
              />{" "}
              Vishal Nagar, Pimple Nilakh, Pune
            </p>
          </div>
          <div className={styles.inclusive}>
            <Tag style={{ margin: "5px" }}>T shirt</Tag>
            <Tag style={{ margin: "5px" }}>Medal</Tag>
            <Tag style={{ margin: "5px" }}>Cap</Tag>
            <Tag style={{ margin: "5px" }}>Refreshments</Tag>
            <Tag style={{ margin: "5px" }}>E Certificate</Tag>
          </div>
          <div className={styles.price}>
            <h1>
              ₹ 499 <span>/Ticket</span>
            </h1>
            <Link to="register" spy={true}>
              <button className={styles.button}>Register Now</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalkathonDetails;
