import React, { useRef, useState, useEffect } from "react";
import styles from "./LandingPage.module.css";
import Navbar from "../../Components/Navbar/Navbar";
import Register from "../../Components/RegistrationForm/Register";
import Walkathonsection from "../../Components/WalkathonSection/Walkathonsection";
import HomeMain from "../../Components/HomeMain/HomeMain";
import WalkathonDetails from "../../Components/WalkathonDetails/WalkathonDetails";
import Annadan from "../../Components/Annadan/Annadan";
import Gallery from "../../Components/Gallery/Gallery";
import { Element } from "react-scroll";
import { motion } from "framer-motion";
import useInView from "../../Hooks/useInView";
import StickyButton from "../../Components/stickybutton/StickyButton";

const LandingPage = () => {
  const homeRef = useRef(null);
  const walkathonRef = useRef(null);
  const detailsRef = useRef(null);
  const registerRef = useRef(null);
  const annadanRef = useRef(null);
  const galleryRef = useRef(null);

  const [hasHomeAnimated, setHasHomeAnimated] = useState(false);
  const [hasWalkathonAnimated, setHasWalkathonAnimated] = useState(false);
  const [hasDetailsAnimated, setHasDetailsAnimated] = useState(false);
  const [hasRegisterAnimated, setHasRegisterAnimated] = useState(false);
  const [hasAnnadanAnimated, setHasAnnadanAnimated] = useState(false);
  const [hasGalleryAnimated, setHasGalleryAnimated] = useState(false);

  const isHomeInView = useInView(homeRef, { threshold: 0.5 });
  const isWalkathonInView = useInView(walkathonRef, { threshold: 0.5 });
  const isDetailsInView = useInView(detailsRef, { threshold: 0.5 });
  const isRegisterInView = useInView(registerRef, { threshold: 0.5 });
  const isAnnadanInView = useInView(annadanRef, { threshold: 0.5 });
  const isGalleryInView = useInView(galleryRef, { threshold: 0.5 });

  // Effect to update the animation state once the section comes into view
  
  useEffect(() => {
    if (isHomeInView && !hasHomeAnimated) setHasHomeAnimated(true);
    if (isWalkathonInView && !hasWalkathonAnimated) setHasWalkathonAnimated(true);
    if (isDetailsInView && !hasDetailsAnimated) setHasDetailsAnimated(true);
    if (isRegisterInView && !hasRegisterAnimated) setHasRegisterAnimated(true);
    if (isAnnadanInView && !hasAnnadanAnimated) setHasAnnadanAnimated(true);
    if (isGalleryInView && !hasGalleryAnimated) setHasGalleryAnimated(true);
  }, [
    isHomeInView, hasHomeAnimated,
    isWalkathonInView, hasWalkathonAnimated,
    isDetailsInView, hasDetailsAnimated,
    isRegisterInView, hasRegisterAnimated,
    isAnnadanInView, hasAnnadanAnimated,
    isGalleryInView, hasGalleryAnimated
  ]);

  return (
    <div className={styles.landingPageMain}>
      <Navbar />
      <Element name="home" className={styles.section}>
        <motion.div
          ref={homeRef}
          className={styles.pageSections}
          initial={{ opacity: 0, y: 150 }}
          animate={
            hasHomeAnimated ? { opacity: 1, y: 0 } : { opacity: 0, y: 150 }
          }
          transition={{ duration: 0.5, bounce: 0.5, delay: 0.2 }}
        >
          <HomeMain />
        </motion.div>
      </Element>
      <Element name="walkathon" className={styles.section}>
        <motion.div
          ref={walkathonRef}
          className={styles.pageSections}
          initial={{ opacity: 0, y: 150 }}
          animate={
            hasWalkathonAnimated ? { opacity: 1, y: 0 } : { opacity: 0, y: 150 }
          }
          transition={{ duration: 0.5, bounce: 0.5, delay: 0.1 }}
        >
          <Walkathonsection />
        </motion.div>
      </Element>
      <Element name="details" className={styles.section}>
        <motion.div
          ref={detailsRef}
          className={styles.pageSections}
          initial={{ opacity: 0, y: 150 }}
          animate={
            hasDetailsAnimated ? { opacity: 1, y: 0 } : { opacity: 0, y: 150 }
          }
          transition={{ duration: 0.5, bounce: 0.5 }}
        >
          <WalkathonDetails />
        </motion.div>
      </Element>
      <Element name="register" className={styles.section}>
        <motion.div
          ref={registerRef}
          className={styles.pageSections}
          initial={{ opacity: 0, y: 150 }}
          animate={
            hasRegisterAnimated ? { opacity: 1, y: 0 } : { opacity: 0, y: 150 }
          }
          transition={{ duration: 0.5, bounce: 0.5 }}
        >
          <Register />
        </motion.div>
      </Element>
      <Element name="annadan" className={styles.section}>
        <motion.div
          ref={annadanRef}
          className={styles.pageSections}
          initial={{ opacity: 0, y: 150 }}
          animate={
            hasAnnadanAnimated ? { opacity: 1, y: 0 } : { opacity: 0, y: 150 }
          }
          transition={{ duration: 0.5, bounce: 0.5 }}
        >
          <Annadan />
        </motion.div>
      </Element>
      <Element name="gallery" className={styles.section}>
        <motion.div
          className={styles.pageSections}
          initial={{ opacity: 0,}}
          animate={{ opacity: 1, }}
          transition={{ duration: 0.5, bounce: 0.5 }}
        >
          <Gallery />
        </motion.div>
      </Element>
      <StickyButton />
    </div>
  );
};

export default LandingPage;
