import { Route, Routes } from "react-router-dom";
import "./App.css";
import LandingPage from "./Pages/LandingPage/LandingPage";
import Register from "./Components/RegistrationForm/Register";
import Status from "./Pages/Status/Status";
import Footer from "./Components/Footer/Footer";
function App() {
  return (
    <div className="App">
      {/* <LandingPage /> */}
      <Routes>
        <Route index path="/" element={<LandingPage />} />
        <Route path="/status" element={<Status />} />
        <Route path="/registration" element={<Register />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
