import React from "react";
import styles from "./navbar.module.css";
import Hamburger from "./HamburgerNavbar/Hamburger";
import { Link } from "react-scroll";

const Navbar = () => {
  return (
    <div className={styles.navbarWrapper}>
      <div className={styles.navbarMain}>
        <ul className={styles.navbarList}>
          <Link
            to="home"
            className={styles.navbarItem} // Always applied
            activeClass={styles.active} // Applied when the link is 'active' based on scroll position
            spy={true}
            offset={-70}
          >
            Home
          </Link>
          <Link
            to="walkathon"
            className={styles.navbarItem}
            activeClass={styles.active}
            spy={true}
          >
            Walkathon
          </Link>
          <Link
            to="details"
            className={styles.navbarItem}
            activeClass={styles.active}
            spy={true}
          >
            Details
          </Link>
          <Link
            to="register"
            className={styles.navbarItem}
            activeClass={styles.active}
            spy={true}
          >
            Register
          </Link>
          <Link
            to="annadan"
            className={styles.navbarItem}
            activeClass={styles.active}
            spy={true}
          >
            Annadan
          </Link>
          <Link
            to="gallery"
            className={styles.navbarItem}
            activeClass={styles.active}
            spy={true}
          >
            Gallery
          </Link>
        </ul>
      </div>
      <div className={styles.hamburgerWrapper}>
        <Hamburger />
      </div>
    </div>
  );
};

export default Navbar;
