import React, { useState, useEffect } from "react";
import styles from "./countdown.module.css";

const Countdown = () => {
  const [daysleft, setDaysleft] = useState(0);
  const [hoursleft, setHoursleft] = useState(0);
  const [minutesleft, setMinutesleft] = useState(0);
  const [secondsleft, setSecondsleft] = useState(0);

  useEffect(() => {
    const countDownDate = new Date("Jul 28, 2024 00:00:00").getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      if (distance <= 0) {
        setDaysleft(0);
        setHoursleft(0);
        setMinutesleft(0);
        setSecondsleft(0);
        clearInterval(interval); // Stop the interval when countdown ends
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setDaysleft(days);
        setHoursleft(hours);
        setMinutesleft(minutes);
        setSecondsleft(seconds);
      }
    };

    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.countDownMain}>
      <div className={styles.countDownItem}>
        <p className={styles.counter}>{daysleft}</p>
        <p>Days</p>
      </div>
      <div className={styles.countDownItem}>
        <p className={styles.counter}>{hoursleft}</p>
        <p>Hours</p>
      </div>
      <div className={styles.countDownItem}>
        <p className={styles.counter}>{minutesleft}</p>
        <p>Minutes</p>
      </div>
      <div className={styles.countDownItem}>
        <p className={styles.counter}>{secondsleft}</p>
        <p>Seconds</p>
      </div>
    </div>
  );
};

export default Countdown;
