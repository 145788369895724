import React, { useState } from "react";
import styles from "./hamburger.module.css";
import { AnimatePresence, motion } from "framer-motion";
import Hamburger from "hamburger-react";
import { Link } from "react-scroll";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.hamburgerMain}>
      <Hamburger
        size={32}
        direction="right"
        distance="md"
        duration={0.8}
        toggled={isOpen}
        toggle={setIsOpen}
        animateOnMount={true}
        color="black"
      />
      <AnimatePresence>
        {isOpen && (
          <motion.ul
            initial={{ y: "-100vw", height: 0 }} // Start from a collapsed state
            animate={{ y: 0, height: "auto" }} // Animate to the full height
            exit={{ y: "-100vw", height: 0 }} // Optional: collapse when exiting
            transition={{ duration: 0.5, ease: "easeInOut" }}
            style={{ overflow: "hidden" }}
            className={styles.hamburgerList}
          >
            <Link
              onClick={() =>
                setTimeout(() => {
                  setIsOpen(false);
                }, 100)
              }
              to="home"
              className={styles.navbarItem}
              activeClass={styles.active}
              spy={true}
            >
              Home
            </Link>
            <Link
              onClick={() =>
                setTimeout(() => {
                  setIsOpen(false);
                }, 100)
              }
              to="walkathon"
              className={styles.navbarItem}
              activeClass={styles.active}
              spy={true}
            >
              Walkathon
            </Link>
            <Link
              onClick={() =>
                setTimeout(() => {
                  setIsOpen(false);
                }, 100)
              }
              to="details"
              className={styles.navbarItem}
              activeClass={styles.active}
              spy={true}
            >
              Details
            </Link>
            <Link
              onClick={() =>
                setTimeout(() => {
                  setIsOpen(false);
                }, 100)
              }
              to="register"
              className={styles.navbarItem}
              activeClass={styles.active}
              spy={true}
            >
              Register
            </Link>
            <Link
              onClick={() =>
                setTimeout(() => {
                  setIsOpen(false);
                }, 100)
              }
              to="annadan"
              className={styles.navbarItem}
              activeClass={styles.active}
              spy={true}
            >
              Annadan
            </Link>
            <Link
              onClick={() =>
                setTimeout(() => {
                  setIsOpen(false);
                }, 100)
              }
              to="gallery"
              className={styles.navbarItem}
              activeClass={styles.active}
              spy={true}
            >
              Gallery
            </Link>
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HamburgerMenu;
